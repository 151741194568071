.reviews-inner {
  display: flex;
}

.reviews-videos {
  width: 45.625vw;
  margin-right: 5.208vw;
}

.reviews-photo {
  margin: 0 0.833vw;
}

.reviews-video {
  width: 45.625vw;
  height: 32.1875vw;
  border-radius: 35px;
  overflow-y: hidden;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(22, 22, 22, 0.65);
    z-index: 2;
  }

  &__img {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;

    img {
      position: absolute;
      max-width: unset;
      max-height: unset;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }

  .play {
    z-index: 3;
    width: 5.833vw;
    height: 5.833vw;

    img {
      width: 2.135vw;
    }
  }
}

.reviews-photos-wrapper {
  position: relative;
}

.reviews-photos {
  width: 47.65625vw;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;

  &__iphone {
    display: flex;
    width: 15.99vw;
  }

  .slick-prev {
    left: -4.0125vw;

    &::before {
      background-image: url(../img/arrow-prev-dark.png);
    }
  }

  .slick-next {
    right: 11.3875vw;

    &::before {
      background-image: url(../img/arrow-next-dark.png);
    }
  }
}

.br-mob {
  display: none !important;
}

@media (max-width: 900px) {
  .br-mob {
    display: block !important;
  }

  .reviews-videos {
    width: 100%;
    margin-right: 0;
    margin-bottom: 4rem;
  }

  .reviews-video {
    width: 100%;
    height: 32.1875rem;

    .play {
      width: 5.833rem;
      height: 5.833rem;

      img {
        width: 2.135rem;
      }
    }
  }

  .reviews-photos {
    width: 21rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &__iphone {
      width: 23rem;
    }

    .slick-prev {
      left: -4.0125rem;

    }

    .slick-next {
      right: -4.0125rem;

    }
  }

  .reviews-inner {
    flex-direction: column;
    align-items: center;
  }

  .reviews-photos-wrapper {
    width: 23rem;
  }
}