.object {
  margin: 0 1.302vw;
}

.objects-items {
  color: #fefefe;
}

.object-slider-wrapper {
  position: relative;
  border-radius: 30px;
  overflow-y: hidden;
  margin-bottom: 1.042vw;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, rgba(19, 19, 21, 0.87) 0%, rgba(30, 31, 37, 0.02) 98%, rgba(30, 31, 37, 0) 100%);
    z-index: 1;
    pointer-events: none;
  }
}

.object-slider {
  &__label {
    letter-spacing: 2.8px;
    position: absolute;
    transform: rotate(-90deg);
    left: 0.3vw;
    top: 6.2vw;
    z-index: 1;
    pointer-events: none;
  }

  &__title {
    position: absolute;
    left: 3.28125vw;
    bottom: 3.646vw;
    z-index: 1;
    pointer-events: none;
  }

  &__img {
    position: relative;
    width: 28.125vw;
    height: 36.458vw;

    img {
      position: absolute;
      max-width: unset;
      max-height: unset;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }
}

.object-nav {
  margin: 0 -0.521vw;

  &__img {
    position: relative;
    width: 6.25vw;
    height: 7.8125vw;
    border-radius: 15px;
    overflow-y: hidden;
    margin: 0 0.521vw;

    img {
      position: absolute;
      max-width: unset;
      max-height: unset;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }
}

.slick-dots {
  bottom: -3.125vw;
}

.design--catalog {
  .design-form {
    padding: 3.192vw 6.042vw;
    overflow-y: hidden;
  }

  .design-title {
    display: flex;
    align-items: center;
    gap: 1.302vw;

    .fz115 {
      position: relative;
    }

    .hint {
      top: 2.2vw;
      right: unset;
      left: -0.5vw;
    }

    .hint-icon {
      width: 1.302vw;
    }
  }

  .design-form__right {
    position: relative;
    z-index: 1;

    .btn {
      margin-bottom: 1.146vw;
    }
  }

  .design-form__img {
    width: 45.052vw;
    right: 21.198vw;
  }
}

@media (max-width: 900px) {
  .object {
    margin: 0 1.302rem;
  }

  .object-slider-wrapper {
    margin-bottom: 1.042rem;
  }

  .object-slider {
    &__label {
      left: -1.5rem;
      top: 6.2rem;
    }

    &__title {
      left: 3.28125rem;
      bottom: 3.646rem;
    }

    &__img {
      width: 28.125rem;
      height: 36.458rem;
    }
  }

  .object-nav {
    margin: 0 -0.521rem;

    &__img {
      width: 6.25rem;
      height: 7.8125rem;
      margin: 0 0.521rem;
    }
  }

  .slick-dots {
    bottom: -3.125rem;
  }

  .design--catalog {
    .design-form {
      padding: 2rem;
      padding-bottom: 20rem;
    }

    .design-title {
      gap: 1.302rem;

      .hint {
        top: 2.2rem;
        left: -0.5rem;
      }

      .hint-icon {
        width: 1.302rem;
      }
    }

    .design-form__right {
      .btn {
        margin-bottom: 1.146rem;
      }
    }

    .design-form__img {
      width: 25.052rem;
      right: 5.198rem;
    }
  }

  .fz115 {
    font-size: 5.99rem;
  }
}