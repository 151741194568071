* {
	box-sizing: border-box; }
html {
	font-size: 10px;
	scroll-behavior: smooth; }
body {
	font-family: "Jost", sans-serif;
	line-height: 1.2;
	font-weight: 400;
	overflow-x: hidden;
	color: #282828;
	background-color: #ffffff;
	position: relative;
	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility; }
h1, h2, h3, h4, h5, h6 {
	margin: 0;
	padding: 0; }

a, button, input, textarea, select {
	outline: none; }
ul {
	list-style-type: none;
	margin: 0;
	padding: 0; }

a {
	text-decoration: none;
	color: #282828; }
p {
	margin: 0; }
img {
	max-width: 100%;
	max-height: 100%;
	align-self: center; }

.rr {
	font-family: "RoadRadio", sans-serif; }
.slick-slide, .slick-slide * {
  outline: none !important; }

.black {
	color: #5A5A59; }
.poe {
	pointer-events: none; }

.mrm {
	margin-right: 0.5vw; }
.mr {
	margin-right: 1vw; }
.mrs {
	margin-right: 1.5vw; }
.mrx {
	margin-right: 0.781vw; }
.lt {
	color: #fff; }
.ggc {
	color: #69bdad; }
.gray {
	color: #808080; }
.asfs {
	align-self: flex-start; }
.title-lg {
	font-size: 3.125vw;
	font-weight: 400;
	line-height: 1; }

.title-num {
	font-size: 5.208vw; }
.title72 {
	font-size: 3.75vw; }
.tac {
	text-align: center; }

.fw1 {
	font-weight: 100; }
.fw3 {
	font-weight: 300; }
.fw5 {
	font-weight: 500; }
.fw4 {
	font-weight: 400; }
.fw6 {
	font-weight: 600; }
.fw7 {
	font-weight: 700; }
.fw8 {
	font-weight: 800; }
.fwb {
	font-weight: bold; }
.fw9 {
	font-weight: 900; }
.fsi {
	font-style: italic; }
.tar {
	text-align: right; }
.tdt {
	text-decoration: line-through; }
.tdu {
	text-decoration: underline; }

.center {
	text-align: center; }
.ttu {
	text-transform: uppercase; }

.ttn {
	font-family: TTNorms; }

.title {
	font-size: 2.76vw; }

.title-lg {}
// 30 -36
.t-min {
	font-size: 1.875vw; }
.t-ss {
	font-size: 1.5vw; }
.t-min2 {
	font-size: 1.5625vw; }

// 16-17px
.text {
	font-size: 0.885vw; }

// 18-19
.text-md {
	font-size: 1.042vw; }
.tg {
	color: #f78449; }
.tg2 {
	color: #afe4d6; }
a.text-min {
	color: #181818;
	transition: all 0.4s;
	&:hover {
		color: #ff7200; } }
// 14-15
.small-text {
	font-size: 0.92vw; }
.tsm8 {
	font-size: 0.55vw; }
.tsm10 {
	font-size: 0.61vw; }
.tsm11 {
	font-size: 0.7vw; }
.tsm12 {
	font-size: 0.677vw; }
.tsm13 {
	font-size: 0.88125vw; }
// 24
.text24 {
	font-size: 1.25vw; }
// 23
.text23 {
	font-size: 1.3vw; }
// 21
.text21 {
	font-size: 1.2vw; }
.container {
	max-width: 89.583vw;
	margin: 0 auto;
	&-big {
		max-width: 89.53125vw;
		margin: 0 auto; }
	&-min {
		max-width: 81.771vw;
		margin: 0 auto; }
	&-mid {
		max-width: 84.896vw;
		margin: 0 auto; }
	&--left {
		width: 100%;
		padding-left: 4.852vw; }
	&--right {
		width: 100%;
		padding-right: 7.5vw; } }

.mlg {
	margin-bottom: 4.167vw; }
.msm {
	margin-bottom: 3.333vw; }
.mb {
	margin-bottom: 2.083vw; }
.mbm {
	margin-bottom: 0.7vw; }
.mbs {
	margin-bottom: 0.41vw; }
.stop {
	overflow: hidden; }

.section {
	padding: 6vw 0;
	position: relative; }

.max {
	width: 100%; }
.btn-text {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	white-space: nowrap; }
.gr {
	color: #8D8D8D; }
.btn {
	padding: 2.292vw 3.177vw;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	transition: all 0.4s;
	border-radius: 100px;
	background-color: #ac8a10;
	z-index: 6;
	cursor: pointer;
	border: none;
	position: relative;
	color: #ffffff;
	letter-spacing: 0.145vw;
	font-weight: 400;
	&--arrow {
		img {
			width: 0.677vw;
			margin-left: 1.302vw; } }
	.btn-blick {
		position: absolute; }
	&:not(:disabled) .btn-blick {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border-radius: 100px;
		overflow: hidden;
		&:after {
			margin-left: -30px;
			content: " ";
			width: 30px;
			height: 300px;
			background: #fff;
			animation-delay: 0.05s;
			position: absolute;
			left: -40px;
			top: -100px {
		 	animation-timing-function: ease-in-out; }
			transition: all 0.1s;
			animation-name: slideme;
			animation-duration: 3.5s;
			animation-iteration-count: infinite;
			transform: rotate(45deg); } }
	&:hover {
		background-color: lighten(#ac8a10, 5%); }
	&.btn--gray {
		background-color: #f0f0f0;
		box-shadow: none;
		color: #282728;
		&:hover {
			background-color: darken(#f0f0f0, 5%); } }
	&.btn--light {
		padding: 1.40625vw 1.979vw;
		background-color: #ffffff;
		color: #000000;
		letter-spacing: 0.042vw;
		&:hover {
			background-color: #ac8a10;
			color: #ffffff; } }
	&.btn--outline {
		border-radius: 3px;
		border: 1px solid #ffffff;
		background: transparent;
		box-shadow: none;
		color: #ffffff;
		padding: 1.302vw 1.5625vw;
		&:hover {
			background-color: #4e9ef7;
			border-color: #4e9ef7; } }
	&.btn--blue {
		background-color: #0d4ea1;
		height: 3.28125vw;
		padding: 0 4.21875vw 0 3.229vw;
		&::after {
			content: '';
			border: solid #ffffff;
			border-width: 0 .1vw .1vw 0;
			display: inline-block;
			padding: .12vw;
			position: absolute;
			top: 50%;
			right: 3.3vw;
			transform: translateY(-50%) rotate(-45deg); }
		&:hover {
			background-color: darken(#0d4ea1, 5%); } }

	&.light {
		color: #191919;
		background-image: linear-gradient(to top, #ffe97e 0%, #fdf7ad 100%);
		box-shadow: 0 8px 13px rgba(#d6ba5c, 0.49), inset 0 -4px 1px rgba(#d6ba5c,1);
		&:hover {
			box-shadow: 0 8px 13px rgba(#d6ba5c, 0.49), inset 0 4px 1px rgba(#d6ba5c,1); } }
	&.bord {
		border-radius: 5px;
		border: 1px solid #dddddd;
		background: #ffffff;
		box-shadow: 0px 0px 2px #a7a7a7;
		color: #dddddd;
		&:hover {
			box-shadow: 0px 0px 20px #a7a7a7; }
		&.bdr {
			border: 1px solid #1491d0;
			background: #ffffff;
			box-shadow: 0px 0px 2px #1491d0;
			color: #1491d0;
			&:hover {
				box-shadow: 0px 0px 20px #1491d0; } } }

	&.big {
		padding: 1.5vw 1vw; }
	&.min {
		padding-top: 0.6vw;
		padding-bottom: 0.6vw; } }
.bubbly-button {
	&:not(:disabled) .blick {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border-radius: 5px;
		overflow: hidden;
		pointer-events: none;
		&:after {
			margin-left: -30px;
			content: " ";
			width: 30px;
			height: 300px;
			background: #fff;
			animation-delay: 0.05s;
			position: absolute;
			left: -40px;
			top: -150px {
		 	animation-timing-function: ease-in-out; }
			transition: all 0.1s;
			animation-name: slideme;
			animation-duration: 3.5s;
			animation-iteration-count: infinite;
			transform: rotate(45deg); } }
	&:hover {
		.btn-img {
			transform: translateY(5px); } } }
.btn-cent {
	display: flex !important;
	align-items: center;
	justify-content: center; }
.btn-img {
	width: 1.5vw;
	height: 1.5vw;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	transition: all 0.4s ease; }
.burger {
	width: 3.7rem;
	height: 4rem;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	cursor: pointer;
	margin-top: 0.3rem;
	span {
		display: block;
		width: 65%;
		height: 1px;
		border-radius: 4px;
		position: relative;
		background-color: #f7844a;
		&:before, &:after {
			content: '';
			position: absolute;
			display: block;
			width: 150%;
			height: 1px;
			right: 0;
			background-color: #f7844a;
			border-radius: 4px; }
		&:before {
			top: -1rem; }
		&:after {
			bottom: -1rem; } }
	&__wrap {
		text-transform: uppercase;
		color: #d7d7d7;
		display: flex;
		align-items: center;
		font-weight: 500;
		cursor: pointer;
		flex-wrap: wrap;
		flex-direction: column;
		align-items: center;
		transition: all 0.4s;
		display: none;
		width: 0;
		&:hover {
			color: #f7844a; } } }
.close-nav {
 }	// display: none

.link {
	transition: all 0.4s;
	position: relative;
	display: inline-flex;
	align-items: center;
	letter-spacing: 0.38px;
	color: #ffffff;
	&--underline {
		padding-bottom: 0.16vw;
		&::before {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 1px;
			border-bottom: 1px dashed #3974f4;
			transition: all 0.4s; } }
	&--underline:hover {
		&:before {
			width: 40%; } }
	&:not(.link--underline):hover {
		color: #ad8a06; }
	&.link--colored {
		color: #a98710;
		&::before {
			border-color: #a98710; } } }

input::-webkit-input-placeholder {
  color: #282728; }
input:-ms-input-placeholder {
  color: #282728; }
input::-ms-input-placeholder {
  color: #282728; }
input::placeholder {
  color: #282728; }
textarea::-webkit-input-placeholder {
  color: #282728; }
textarea:-ms-input-placeholder {
  color: #282728; }
textarea::-ms-input-placeholder {
  color: #282728; }
textarea::placeholder {
  color: #282728; }
.inp-min {
	width: 30.5%; }
.inp-md {
	width: 45%; }
.inp-big {
	width: 100%; }
.area {
	padding: 1vw 1vw; }

.arrows {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 4vw;
	height: 4vw;
	z-index: 18;
	transition: all 0.4s;
	cursor: pointer;
	border: none;
	background: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.7vw;
	&.t2 {
		width: 5vw;
		height: 5vw; }
	&.t4 {
		width: 3.5vw;
		height: 3.5vw; }
	&.t6 {
		box-shadow: 0 5px 54px rgba(0, 0, 0, 0.5);
		background-color: #ffffff;
		border-radius: 100%;
		filter: grayscale(1); }
	&:hover {
		opacity: 0.7;
		transform: translateY(-50%) scale(1.1);
		&.t6 {
			filter: grayscale(0);
			opacity: 1; } } }
.slidePrev {
	left: 3.5rem;
	&.t2 {
		left: -2.5vw; }
	&.t4 {
		left: -1.1vw; }
	&.t3 {
		left: 50%;
		top: 0;
		transform: translateX(-50%);
		&:hover {
			opacity: 0.7;
			transform: translateX(-50%) scale(1.1); } } }
.slideNext {
	right: 3.5rem;
	&.t2 {
		right: -2.5vw; }
	&.t4 {
		right: -1.1vw; }
	&.t3 {
		left: 50%;
		bottom: -1vw;
		top: auto;
		right: auto;
		transform: translateX(-50%);
		&:hover {
			opacity: 0.7;
			transform: translateX(-50%) scale(1.1); } } }




.check {
	&-box {
		cursor: pointer;
		display: block; }
	&-line {
		display: flex;
		align-items: center; }
	&-label {
		width: 1.1vw;
		height: 1.1vw;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0.2vw;
		position: relative;
		flex-shrink: 0;
		margin-right: .5vw;
		box-shadow: inset 0 0 10px rgba(140, 140, 140, 0.04);
		border-radius: 2px;
		border: 1px solid #d1d1d1;
		background-color: #ffffff;
		&.v2 {
			border-radius: 100%;
			box-shadow: 0 5px 10px rgba(43, 43, 51, 0.1);
			border: 1px solid #e8e8e8;
			background-color: #ffffff;
			width: 1.5vw;
			height: 1.5vw;
			padding: 0.2vw;
			.check-label-act {
				width: 1vw;
				height: 1vw;
				border-radius: 100%;
				padding: 0.2vw;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: #4ca352; } }
		&-act {
			transition: all 0.4s;
			transform: scale(0); }
		&.min {
			width: 1vw;
			height: 1vw;
			border-radius: 2px; } } }

.block-imp-bf {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border-radius: 5px;
	border: 1px solid #dddddd;
	transition: all 0.4s; }
.block-imp {
	padding: 1.3vw 1.5vw;
	position: relative;
	margin-right: 0.7vw; }

.rhide {
	display: none;
	&:checked ~ span {
		.check-label {
			&-act {
				transform: scale(1); }
			&.min {
				background-color: #5bd273; } } }
	&:checked ~ {
		.block-imp-bf {
			background-color: #4ca352;
			border: 1px solid #4ca352; }
		.tsm13 {
			color: #fff;
			position: relative; }
		.times-block div.fancy-select div.trigger {
			color: #fff !important; } } }
.wac {
	color: #3fbf3f !important; }
.vic {
	color: #8173c7 !important; }
.tgc {
	color: #5fcff2 !important; }
.emc {
	color: #fbc855 !important; }

.vf {
	background-color: #fff;
	padding: 2.6vw 2vw;
	box-shadow: 0 0 40px rgba(133, 138, 160, 0.1);
	transform: skewY(-2deg);
	z-index: 4;
	position: relative;
	&:before {
		position: absolute;
		content: '';
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		border: 1px solid #d1d5e4;
		width: calc(100% - 14px);
		height: calc(100% - 14px);
		z-index: 1; }
	&-wrap {
		transform: skewY(2deg);
		position: relative;
		z-index: 5; } }

.line-tg {
	border-bottom: 1px solid #dcbf8b;
	// #cccdd4
	width: 40%;
	&.min {
		margin-left: auto;
		margin-right: auto;
		width: 7vw;
		border-bottom: 1px solid #cccdd4; } }

.pre {
	position: relative;
	z-index: 4; }

.blc {
	color: #303030 !important; }

.lcb {
	color: #303030;
	transition: all 0.4s;
	&:hover {
		color: #e30613; } }

.aic {
	align-items: center; }
.text-rotate {
	position: absolute;
	left: 0;
	top: 0;
	width: 8vw;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 71;
	&.right {
		left: auto;
		right: 0; }
	&-item {
		position: relative;
		transform: rotate(-90deg);
		white-space: nowrap;
		display: inline-block;
		color: rgba(#8c8c8c, 0.5);
		&:before {
			content: '';
			position: absolute;
			left: -3.4vw;
			top: 50%;
			transform: translateY(-50%);
			border-top: 1px solid #d1d1d1;
			width: 3vw;
			height: 1px; }
		&:after {
			content: '';
			position: absolute;
			right: -3.4vw;
			top: 50%;
			transform: translateY(-50%);
			border-top: 1px solid #d1d1d1;
			width: 3vw;
			height: 1px; }
		&.wh {
			color: #ffff; } } }


.line {
	width: 3vw;
	height: 2px;
	background-color: #1a1a1a;
	background-image: linear-gradient(to right, #f7844a 0%, #ffad2f 100%);
	&.cen {
		margin-left: auto;
		margin-right: auto; }
	&.gr {
		background-color: #333333; }
	&.gr2 {
		background-color: #9d9d9d; }
	&-big {
		width: 8vw; }
	&.big2 {
		width: 17vw; } }
.tal {
	text-align: left; }


.tb {
	color: transparent;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: #fff; }
.tgr {
	background-image: linear-gradient(to right, #f7844a 0%, #ffad2f 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent; }
.poin {
	pointer-events: none;
	position: relative;
	z-index: 43; }


.tlt-mod {
	position: relative;
	&:before {
		content: '';
		position: absolute;
		left: -4.3vw;
		width: 3.5vw;
		height: 2px;
		top: 1.3vw;
		z-index: 12;
		background-color: #1a1a1a;
		background-image: linear-gradient(to right, #f7844a 0%, #ffad2f 100%); }
	&:after {
		content: '';
		position: absolute;
		left: -17.3vw;
		width: 13.5vw;
		height: 1px;
		top: 1.3vw;
		z-index: 9;
		background-color: rgba(#fff, 0.1); }
	&.lth {
		&:after {
			background-color: #dedede; } } }

.text-mod {
	position: relative;
	&:before {
		content: '';
		position: absolute;
		left: -2vw;
		width: 1.5vw;
		height: 2px;
		top: 0.5vw;
		background-color: #1a1a1a;
		background-image: linear-gradient(to right, #f7844a 0%, #ffad2f 100%);
		width: 0; }
	&:after {
		content: '';
		position: absolute;
		left: -2vw;
		width: 1.5vw;
		height: 2px;
		top: 0.5vw;
		background-color: rgba(#fff, 0.2); } }

.jcsb {
	justify-content: space-between; }

.posa {
	position: absolute; }

.fdc {
	flex-direction: column; }
