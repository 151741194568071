.control {
  padding-top: 4.167vw;
  padding-bottom: 0;
}

.control-inner {
  background: url('../img/control-bg.jpg') center no-repeat;
  background-size: cover;
  border-radius: 35px;
  height: 44.271vw;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 5.729vw;
  padding-bottom: 3.90625vw;

  .slick-prev {
    left: -2.8125vw;
  }

  .slick-next {
    right: -2.8125vw;
  }
}

.control-items {
  width: 36.667vw;
}

.control-item {
  padding-top: 3.49vw;
  color: #ffffff;
  width: 17.1875vw;
  height: 23.4375vw;
  border-radius: 30px;
  background-color: #1e1e1e;
  margin: 0 0.625vw;
  overflow-y: hidden;
  text-align: center;
  position: relative;

  &__img {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    width: 100%;
  }
}

@media (max-width: 900px) {
  .control {
    padding-top: 4.167rem;
  }

  .material {
    padding-bottom: 0;
  }

  .control-inner {
    height: auto;
    padding-right: 0;
    padding-bottom: 0;
    background-image: none;
    display: flex;
    justify-content: center;

    .slick-prev {
      left: -2.8125rem;
    }

    .slick-next {
      right: -2.8125rem;
    }
  }

  .header-phone .phone {
    font-size: 1.6rem;
  }

  .control-items {
    width: 100%;
    max-width: 330px;
  }

  .online::before {
    top: 0.6rem;
  }

  .control-item {
    padding-top: 3.49rem;
    width: 17.1875rem;
    height: 23.4375rem;
    margin: 0 0.625rem;
  }

  .fz64 br {
    display: none;
  }
}