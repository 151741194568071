.compare {
  .twentytwenty-container {
    height: 41.667vw !important;
  }
}

.compare-click {
  display: flex;
  align-items: center;
  justify-content: center;

  &__img {
    display: flex;
    width: 1.40625vw;
    margin-right: 0.833vw;
  }
}

.twentytwenty-horizontal .twentytwenty-handle {
  width: 4.167vw;
  height: 4.167vw;
  box-shadow: 0 0 49px rgba(40, 39, 40, 0.25);
  border: none;
  background-color: #ac8a10;
  background-image: none;

  &::before {
    margin-bottom: 2.23vw;
  }

  &::after {
    margin-top: 2.23vw;
  }

  .twentytwenty-left-arrow {
    display: flex;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 0;
    width: 1.71875vw;
    height: 1.09375vw;
    background-image: url(../img/twenty-arrows.png);
    background-size: contain;
    margin-left: 0;
    margin-top: 0;
  }

  .twentytwenty-right-arrow {
    display: none;
  }
}

.slick-prev, .slick-next {
  width: 1.09375vw;
  height: 2.135vw;
  z-index: 1;

  &::before {
    content: '';
    display: flex;
    width: 100%;
    height: 100%;
    background-size: contain;
  }
}

.slick-prev {
  left: 2.8125vw;

  &::before {
    background-image: url(../img/arrow-prev.png);
  }
}

.slick-next {
  right: 2.8125vw;

  &::before {
    background-image: url(../img/arrow-next.png);
  }
}

.slick-dots li {
  width: 0.521vw;
  height: 0.521vw;
  margin: 0 0.885vw;
}

.slick-dots li button {
  width: 0.521vw;
  height: 0.521vw;
  padding: 0;
  border-radius: 50%;
  background-color: rgb(230, 229, 229);
  transition: 0.3s;
  position: relative;
  
  &:hover {
    background-color: #ac8a10;

    &::before {
      opacity: 0;
    }
  }
}

.slick-dots li.slick-active button {
  background-color: #ac8a10;

  &::before {
    opacity: 1;
  }
}

.slick-dots li button:before {
  content: '';
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #e2d6ac;
  transition: 0.3s;
  font-size: 0;
  width: calc(100% + 0.417vw);
  height: calc(100% + 0.417vw);
  opacity: 0;
  z-index: -1;
}

@media (max-width: 900px) {
  .compare {
    .twentytwenty-container {
      //height: 41.667rem !important;
    }
  }

  .compare-click {
    &__img {
      width: 2.7rem;
      margin-right: 0.833rem;
    }
  }

  .twentytwenty-horizontal .twentytwenty-handle {
    width: 4.167rem;
    height: 4.167rem;

    &::before {
      margin-bottom: 2.23rem;
    }

    &::after {
      margin-top: 2.23rem;
    }

    .twentytwenty-left-arrow {
      width: 1.71875rem;
      height: 1.09375rem;
    }
  }

  .slick-prev, .slick-next {
    width: 1.09375rem;
    height: 2.135rem;
  }

  .slick-prev {
    left: 2.8125rem;
  }

  .slick-next {
    right: 2.8125rem;
  }

  .slick-dots li {
    width: 0.521rem;
    height: 0.521rem;
    margin: 0 0.885rem;
  }

  .slick-dots li button {
    width: 0.521rem;
    height: 0.521rem;
  }

  .slick-dots li button:before {
    width: calc(100% + 0.417rem);
    height: calc(100% + 0.417rem);
  }
}