.cost {
  padding-top: 2.34375vw;
  padding-bottom: 0;
}

.cost-inner {
  padding-left: 3.90625vw;
}

.cost-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cost-item {
  display: flex;
  align-items: center;

  &__check {
    display: flex;
    width: 0.78125vw;
    margin-right: 1.927vw;
  }
}

@media (max-width: 900px) {
  .cost {
    padding-top: 0;
  }

  .cost-inner {
    padding-left: 0;
  }

  .cost-items {
    flex-wrap: wrap;
  }

  .cost-item {
    margin-bottom: 1rem;

    &__check {
      width: 0.78125rem;
      margin-right: 1.927rem;
    }
  }
}