.steps {
  padding-bottom: 2.083vw;
}

.steps-items {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.steps-line {
  width: 100%;
  height: 1px;
  border-top: 2px dotted #c3cbd9;
  position: absolute;
  left: -10vw;
  top: 1.2vw;
}

.steps-item {
  &:hover {
    .steps-item__check {
      background-color: #ac8a10;
    }
  }

  &__check {
    display: flex;
    width: 2.604vw;
    height: 2.604vw;
    background-color: #e7e7e7;
    border-radius: 50%;
    margin-bottom: 2.34375vw;
    position: relative;
    transition: 0.3s;

    &::before {
      content: '';
      width: 0.78125vw;
      height: 0.78125vw;
      background-color: #ffffff;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

@media (max-width: 900px) {
  .steps {
    padding-bottom: 0;
  }

  .steps-line {
    display: none;
  }

  .steps-items {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .steps-item {
    width: 49%;
    margin-bottom: 2rem;

    br {
      display: none;
    }

    &__check {
      width: 2.604rem;
      height: 2.604rem;
      margin-bottom: 2.34375rem;

      &::before {
        width: 0.78125rem;
        height: 0.78125rem;
      }
    }
  }
}