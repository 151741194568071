.quiz-inner {
  display: flex;
  justify-content: space-between;
}

.quiz-right {
  padding-top: 3.385vw;
  padding-bottom: 1.354vw;
  width: 24.479vw;
  border-radius: 45px;
  background: #000 url('../img/quiz-right-bg.png') center no-repeat;
  background-size: cover;
  text-align: center;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &__bottom {
    position: relative;
    z-index: 1;
  }

  .colored {
    color: #c9a218;
  }
}

.quiz-gift {
  position: relative;
  text-align: left;
  line-height: 1.302vw;

  &__img {
    display: flex;
    position: absolute;
  }

  &--price {
    align-self: flex-start;
    margin-bottom: 4.115vw;
    padding-left: 3.75vw;

    .quiz-gift__img {
      width: 8.229vw;
      top: 50%;
      transform: translateY(-50%);
      right: -8.59375vw;
    }
  }

  &--gifts {
    align-self: flex-end;
    padding-right: 3.177vw;
    margin-bottom: 18.542vw;

    .quiz-gift__img {
      width: 7.292vw;
      top: -3.021vw;
      left: -8.59375vw;
    }
  }
}

.quiz-person {
  position: absolute;
  left: 1.09375vw;
  bottom: 0;

  &__img {
    display: flex;
    width: 100%;
  }

  &__text {
    position: absolute;
    bottom: 11.5625vw;
    right: 2.96875vw;
    text-align: left;

    .fz16 {
      opacity: 0.43;
    }
  }
}

.quiz-form {
  padding: 3.125vw 5.625vw;
  width: 61.198vw;
  border-radius: 45px;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;

  &.quiz-form--semifinal {
    padding: 0;
    width: 100%;
  }
}

.quiz-progress {
  position: relative;
  margin-bottom: 2.1875vw;

  p {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 1;
  }

  &__bar {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 2.5vw;
    border-radius: 24px;
    background-color: #fff;
  }

  &__inner {
    position: absolute;
    left: 0.26vw;
    top: 50%;
    transform: translateY(-50%);
    height: 2.083vw;
    border-radius: 20px;
    background-color: #ac8a10;
    animation: loadingBg 15s infinite linear;
    background-image: url(../img/loading.png);
    width: 12%;
  }
}

.quiz-options {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &--three {
    .quiz-option {
      width: 15.625vw;
    }

    .quiz-option__img {
      height: 22.292vw;
    }
  }

  &--four {
    .quiz-option {
      width: 11.71875vw;

      &__wrapper {
        margin-bottom: 1.71875vw;
      }
    }

    .quiz-option__img {
      height: 22.1875vw;
    }
  }

  &--inline {
    .quiz-option {
      width: 24.392vw;
      margin-bottom: 1.25vw;
    }

    .quiz-option__wrapper {
      padding: 0.885vw 1.5625vw;
      border-radius: 20px;
      background-color: #ededed;
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }

    .quiz-option__checkbox {
      position: relative;
      transform: none;
      left: unset;
      bottom: unset;
      margin-right: 1.09375vw;
    }
  }
}

.quiz-hint-wrapper {
  position: relative;
}

.hint {
  position: absolute;
  right: -0.677vw;
  top: -0.78125vw;

  &:hover {
    .hint-hover {
      opacity: 1;
    }
  }
}

.hint-icon {
  display: flex;
  width: 0.78125vw;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ac8a10;
    opacity: .2;
    border-radius: 50%;
    animation: animPulse 2s linear infinite;
    z-index: -1;
  }
}

.hint-hover {
  position: absolute;
  left: 50%;
  bottom: 110%;
  width: auto;
  padding: 5px;
  color: #282828;
  box-shadow: 4px 7px 17px -6px #000;
  background-color: #fff;
  text-align: center;
  border-radius: 6px;
  z-index: 33;
  transform: translate(-50%,0);
  opacity: 0;
  transition: opacity .6s;
  line-height: 1;
  pointer-events: none;
}

.quiz-option {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    transition: 0.3s;
  }

  &:hover .quiz-option__checkbox {
    background-color: #ac8a10;
  }

  &__input {
    display: none;

    &:checked {
      & ~ .quiz-option__wrapper {
        p {
          font-weight: 600;
        }

        .quiz-option__checkbox {
          &::before {
            background-color: #ac8a10;
          }

          img:first-child {
            display: none;
          }

          img:last-child {
            display: flex;
          }
        }

        & ~ p {
          font-weight: 600;
        }

        & ~ .quiz-hint-wrapper > p {
          font-weight: 600;
        }
      }
    }
  }

  &__wrapper {
    position: relative;
    margin-bottom: 2.24vw;
    width: 100%;
  }

  &__img {
    display: flex;
    position: relative;
    width: 100%;
    transition: .3s;
    border-radius: 20px;
    overflow: hidden;

    img {
      position: absolute;
      max-width: unset;
      max-height: unset;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }

  &__checkbox {
    width: 2.083vw;
    height: 2.083vw;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -1.0415vw;
    transition: .3s;
    z-index: 2;

    &::before {
      content: '';
      width: 1.354vw;
      height: 1.354vw;
      background-color: #f4f7fa;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      border-radius: 50%;
    }

    img {
      width: 0.573vw;
      z-index: 1;

      &:last-child {
        display: none;
      }
    }
  }
}

.quiz-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
}

.quiz-btn--prev {
  padding: 1.615vw 5vw;
  background-color: #dbdbdb;
  margin-right: 1.302vw;
}

.quiz-btn--next {
  padding: 1.615vw 4.115vw;

  &:hover:disabled .quiz-btn__hint {
    opacity: 1;
  }
}

.btn--arrows {
  gap: 0.46875vw;
}

.btn-arrows {
  display: flex;
  gap: 0.104vw;

  img {
    width: 0.3125vw;
  }
}

.btn-arrow {
  animation: opacity 2s ease-in infinite;

  &:nth-child(2) {
    animation-delay: .2s;
  }

  &:nth-child(3) {
    animation-delay: .3s;
  }
}

.quiz-btn__hint {
  position: absolute;
  left: 102%;
  top: 50%;
  transform: translateY(-50%);
  width: 80%;
  color: #282828;
  box-shadow: 4px 7px 17px -6px #000;
  background-color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 33;
  opacity: 0;
  transition: opacity .6s;
}

.quiz-semifinal {
  padding: 7.083vw 0 6.042vw 10.208vw;
  background: #242323 url('../img/semifinal-bg.png') center no-repeat;
  background-size: cover;
  color: #ffffff;
  text-align: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  border-radius: 45px;
  overflow-y: hidden;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn,
    .input {
      width: 17.34375vw;
      margin-bottom: 0.833vw;
    }

    .input {
      background-color: #f7f6f6;
      color: #000;
    }

    .btn {
      padding: 1.771vw 4.6875vw;
    }
  }

  &__img {
    display: flex;
    width: 56.5625vw;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.quiz-socials {
  display: flex;
  gap: 0.78125vw;
  margin-bottom: 1.09375vw;
}

.quiz-social {
  cursor: pointer;
  min-width: 5.46875vw;

  &__input {
    display: none;

    &:checked~.quiz-social__wrapper {
      box-shadow: 0 7px 27px rgba(40, 40, 40, 0.1);
      background-color: #fff;
    }

    &:checked~.quiz-social__wrapper .quiz-social__text {
      color: #282728;
      font-weight: 700;
    }
  }

  &:hover .quiz-social__wrapper {
    box-shadow: 0 7px 27px rgba(40, 40, 40, 0.1);
    background-color: #fff;

    .quiz-social__text {
      color: #282728;
    }
  }

  &__wrapper {
    padding: 1.042vw 0.698vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.521vw;
    transition: .3s;
    border-radius: 15px;
  }

  &__img {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.604vw;
    height: 2.604vw;

    img {
      width: 1.042vw;
    }
  }

  &.is-whatsapp .quiz-social__img {
    background-color: #25d266;
  }

  &.is-viber .quiz-social__img {
    background-color: #7460f3;
  }

  &.is-telegram .quiz-social__img {
    background-color: #00a2f2;
  }
}

.policy {
  display: flex;
  align-items: center;
  gap: 0.625vw;
  cursor: pointer;
}

.policy__check {
  width: 0.833vw;
  height: 0.833vw;
  border-radius: 4px;
  border: 1px solid #ac8a10;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.policy__check img {
  width: 0.3125vw;
  display: none;
}

.policy input:checked~.policy__check img {
  display: block;
}

.policy input:checked~.policy__check {
  background-color: #ac8a10;
}

@media (max-width: 900px) {
  .quiz-right {
    display: none;
  }

  .quiz-form {
    padding: 2rem;
    width: 100%;
  }

  .quiz-progress {
    margin-bottom: 2.1875rem;

    p {
      white-space: nowrap;
    }

    &__bar {
      height: 2rem;
    }

    &__inner {
      left: 0.26rem;
      height: 2.083rem;
    }
  }

  .quiz-options {

    &--three {
      .quiz-option {
        width: 49%;
        margin-bottom: 1.5rem;
      }

      .quiz-option__img {
        height: 21.615rem;
      }
    }

    &--four {
      .quiz-option {
        width: 49%;

        &__wrapper {
          margin-bottom: 1.71875rem;
        }
      }

      .quiz-option__img {
        height: 21.615rem;
      }
    }

    &--inline {
      .quiz-option {
        width: 100%;
        margin-bottom: 1.25rem;
      }

      .quiz-option__wrapper {
        padding: 0.885rem 1.5625rem;
      }

      .quiz-option__checkbox {
        margin-right: 1.09375rem;
      }
    }
  }

  .hint {
    position: absolute;
    right: 0rem;
    top: -0.78125rem;
  }

  .hint-icon {
    width: 1rem;
  }

  .quiz-option {

    &__wrapper {
      margin-bottom: 2.24rem;
    }

    &__checkbox {
      width: 2.083rem;
      height: 2.083rem;
      bottom: -1.0415rem;

      &::before {
        width: 1.354rem;
        height: 1.354rem;
      }

      img {
        width: 0.573rem;
      }
    }
  }

  .quiz-btn--prev {
    padding: 1.615rem 5rem;
    margin-right: 1.302rem;
  }

  .quiz-btn--next {
    padding: 1.615rem 4.115rem;
  }

  .btn--arrows {
    gap: 0.46875rem;
  }

  .btn-arrows {
    gap: 0.104rem;

    img {
      width: 0.3125rem;
    }
  }

  .quiz-semifinal {
    padding: 2rem;
    padding-bottom: 27rem;

    &__wrapper {

      .btn,
      .input {
        width: 100%;
        margin-bottom: 0.833rem;
      }

      .btn {
        padding: 1.771rem 4.6875rem;
      }
    }

    &__img {
      width: 31.5625rem;
    }
  }

  .quiz-socials {
    gap: 0.78125rem;
    margin-bottom: 1.09375rem;
  }

  .quiz-social {
    min-width: 5.46875rem;

    &__wrapper {
      padding: 1.042rem 0.698rem;
      gap: 0.521rem;
    }

    &__img {
      width: 2.604rem;
      height: 2.604rem;

      img {
        width: 1.042rem;
      }
    }
  }

  .policy {
    gap: 0.625rem;

    p {
      text-align: left;
    }
  }

  .policy__check {
    min-width: 1.3rem;
    height: 1.3rem;
  }

  .policy__check img {
    width: 0.7rem;
  }
}