.variants {
  padding-top: 1.5625vw;
  padding-bottom: 1.5625vw;
}

.variants-items {
  display: flex;
}

.variants__vars {
  letter-spacing: 2.8px;
  opacity: 0;
  transition: 0.3s;
}

.variants-item {
  flex: 1;
  height: 41.667vw;
  color: #ffffff;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  overflow-y: hidden;
  display: flex;
  align-items: flex-end;

  h3 {
    letter-spacing: 3px;
  }

  &:hover {
    .variants__vars {
      opacity: 1;
    }

    .variants-item__wrapper {
      transform: translateY(0);
    }

    .variants-item__list {
      max-height: 600px;
      opacity: 1;
    }

    &::after {
      opacity: 1;
    }

    .variants-item__variants {
      opacity: 0;
    }
  }

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, rgba(19, 19, 21, 0.87) 0%, rgba(30, 31, 37, 0.02) 98%, rgba(30, 31, 37, 0) 100%);
    position: absolute;
    left: 0;
    top: 0;
  }

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(30, 31, 37, 0.75);
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition: 0.4s;
  }

  &__variants {
    position: absolute;
    z-index: 1;
    transform: rotate(-90deg);
    top: 8.5vw;
    left: 0.7vw;
    letter-spacing: 2.8px;
    text-transform: uppercase;
    transition: 0.3s;
  }

  &__wrapper {
    padding-left: 5.208vw;
    padding-bottom: 3.125vw;
    position: relative;
    z-index: 1;
    transform: translateY(11vw);
    transition: 0.4s;
  }

  &__list {
    transition: 0.4s;
    max-height: 0;
    opacity: 0;
  }

  &__bottom {
    display: flex;
    align-items: center;
    margin-top: 2.396vw;

    .btn {
      margin-right: 1.875vw;
    }
  }
}

@media (max-width: 900px) {
  .variants {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem;
  }

  .variants-items {
    flex-wrap: wrap;
    flex-direction: column;
  }

  .variants-item__list {
    max-height: unset;
    opacity: 1;
  }

  .variants-item {
    height: auto;
    width: 100%;

    &::after {
      opacity: 1;
    }

    &__variants {
      top: 8.5rem;
      left: 0.7rem;
      display: none;
    }

    &__wrapper {
      padding: 2rem;
      transform: translateY(0);
    }

    &__bottom {
      margin-top: 2.396rem;

      .btn {
        margin-right: 1.875rem;
      }
    }
  }
}