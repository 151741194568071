.mobs {
	display: none; }
@media (max-width: 900px) {
	html {
		overflow-x: hidden; }
	.title-lg {
		font-size: 3.2rem; }
	.title-mslg {
		font-size: 5.5rem;
		letter-spacing: .2rem; }
	.title-elg {
		font-size: 6.6rem;
		letter-spacing: .2rem; }
	.title {
		font-size: 2.6rem; }
	.tgg {
		padding: 0.1rem 0.4rem;
		margin: 0.2rem 0; }
	.t-min {
		font-size: 1.95rem; }
	.t-ss {
		font-size: 1.7rem; }
	.t-min2 {
		font-size: 1.9rem; }
	.text-tg {
		margin-left: 1.5rem; }
	.text-md {
		font-size: 1.4rem; }
	// 16-17px
	.text {
		font-size: 1.2rem; }

	.small-text {
		font-size: 1.1rem; }
	.tsm8 {
		font-size: 0.8rem; }
	.tsm {
		font-size: 0.9rem; }
	.tsm10 {
		font-size: 1rem; }
	.tsm11 {
		font-size: 1rem; }
	.tsm12 {
		font-size: 1rem; }
	.tsm13 {
		font-size: 1.1rem; }
	.mr1 {
		margin-right: 1rem; }
	.tnum {
		font-size: 2.9rem; }
	// 24
	.text24 {
		font-size: 1.4rem; }
	// 23
	.text23 {
		font-size: 1.3rem; }
	// 21
	.text21 {
		font-size: 1.3rem; }
	.container {
		max-width: 90vw;
		margin: 0 auto;
		&-min {
			max-width: 90vw;
			margin: 0 auto; }
		&-md {
			max-width: 90vw;
			margin: 0 auto; }
		&-mm {
			max-width: 90vw;
			margin: 0 auto; } }
	.mlg {
		margin-bottom: 4.5rem; }
	.msm {
		margin-bottom: 2.4rem; }
	.mb {
		margin-bottom: 1.5rem; }
	.mbm {
		margin-bottom: 0.7rem; }
	.mbs {
		margin-bottom: 0.41rem; }
	.section {
		padding: 6rem 0; }
	.mrm {
		margin-right: 0.5rem; }
	.mr {
		margin-right: 1rem; }
	.mrs {
		margin-right: 1.5rem; } }
