.main {
  background: #fff url(../img/hero-bg.jpg) center no-repeat;
  background-size: cover;
  color: #ffffff;
  position: relative;
}

.main--quiz {
  background-image: url(../img/hero-quiz-bg.jpg);
}

.main--lead {
  background-image: url(../img/hero-lead-bg.jpg);
  overflow: hidden;

  .hero {
    padding-top: 8.802vw;
    padding-bottom: 9.323vw;

    .container {
      align-items: flex-start;
    }
  }
}

.main--thank {
  background-image: url(../img/hero-thank-bg.jpg);

  .hero {
    padding-top: 13.4375vw;
    padding-bottom: 19.6875vw;
  }
}

.lead-wrapper {
  display: flex;
  align-items: center;
  gap: 1.302vw;

  .btn,
  .input {
    width: 17.34375vw;
    height: 4.167vw;
  }

  .input {
    background-color: #f7f6f6;
  }
}

.lead-form {
  padding-left: 4.167vw;
}

.lead-img {
  position: absolute;
  width: 94.271vw;
  right: -31.927vw;
  top: 1.198vw;
  pointer-events: none;
}

.main--404 {
  background-image: url(../img/main-404-bg.jpg);
  overflow-y: hidden;

  .hero {
    position: relative;
    z-index: 1;
    padding-bottom: 18.229vw;

    .fz36 {
      text-align: center;
    }
  }
}

.main-404-img {
  position: absolute;
  display: flex;
  width: 86.042vw;
  left: 50%;
  transform: translateX(-50%);
  bottom: -6vw;
}

.header {

  &__inner {
    padding: 1.458vw 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.logo {
  display: flex;
  align-items: center;

  &__img {
    display: flex;
    width: 9.115vw;
    margin-right: 3.125vw;
  }
}

.btn.btn--viber {
  background-color: rgba(#ffffff, 0.05);
  padding: 0.365vw 0.9375vw 0.365vw 0.417vw;
  color: rgba(#ffffff, 0.35);
  letter-spacing: 0.036vw;

  &:hover {
    background-color: rgba(#7b68f4, 0.5);
  }

  &::before {
    content: '';
    border-radius: 50%;
    width: 0.365vw;
    height: 0.365vw;
    background-color: #52b060;
    position: absolute;
    top: 0.208vw;
    right: 0.15625vw;
    animation: online 2s infinite;
  }

  .btn-viber {
    width: 1.667vw;
    height: 1.667vw;
    border-radius: 50%;
    background-color: #7b68f4;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.46875vw;

    img {
      width: 0.78125vw;
    }
  }
}

.header-phone {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav {
  &__inner {
    padding-top: 1.042vw;
    letter-spacing: 0.28126vw;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      transition: 0.3s;
      color: #ffffff;

      &:hover {
        color: #ad8a06;
      }
    }
  }
}

.hero-text {
  display: flex;
  align-items: center;
}

.circles {
  display: flex;
  align-items: center;
  gap: 0.417vw;
  margin-right: 1.458vw;
  margin-left: 1.458vw;
  margin-top: 0.7vw;

  span {
    width: 0.521vw;
    height: 0.521vw;
    border: 1px solid #ad8a06;
    border-radius: 50%;
    animation: opacity 1s infinite;
    animation-direction: alternate;

    &:nth-child(2) {
      animation-delay: .2s;
    }
  }
}

.hero .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.hero-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6.771vw;

  .btn {
    margin-right: 2.448vw;
  }
}

.hero-time {
  display: flex;
  align-items: center;

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 1.823vw;
    height: 1.823vw;
    background-color: #ffffff;
    position: relative;
    z-index: 1;
    margin-right: 1.51vw;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #ffffff;
      opacity: .2;
      border-radius: 50%;
      animation: animPulse 2s linear infinite;
      z-index: -1;
    }

    img {
      width: 1.09375vw;
    }
  }

  p {
    line-height: 1.354vw;
  }
}

.hero-items {
  display: flex;
}

.hero-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16.979vw;
  height: 6.51vw;
  border: 1px solid rgba(#ffffff, 0.25);
  background-color: rgba(#000000, 0.25);
  margin-left: -1px;

  &__check {
    display: flex;
    width: 0.78125vw;
    margin-right: 1.823vw;
  }
}

.hero-scroll {
  background: #fff url(../img/scroll-bg.jpg) center no-repeat;
  background-size: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 5.208vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 2.083vw;
  }
}

.nav__mob {
  display: none;
}

@media (max-width: 900px) {
  .main--lead {
    .hero {
      .container {
        align-items: center;
      }
    }
  }

  .lead-wrapper {
    gap: 1.302rem;
    flex-direction: column;
    align-items: center;

    .btn,
    .input {
      width: 100%;
      height: 4.5rem;
    }
  }

  .lead-form {
    padding-left: 0;
  }

  .lead-img {
    width: 94.271rem;
    right: -31.927rem;
    top: 1.198rem;
    display: none;
  }
  .main--quiz {
    .fz36 {
      text-align: center;
      br {
        display: none;
      }
    }
  }
  .nav__mob {
    display: inline-flex;
    align-items: center;
    position: absolute;
    right: 1rem;
    top: 2.7rem;
  }

  .nav__burger {
    width: 2rem;
    height: 1.7rem;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    display: flex;
  }

  .nav__burger-item {
    display: block;
    width: 100%;
    height: 3px;
    border-radius: 4px;
    position: relative;
    background-color: #a78b31;
  }

  .header {
    &__inner {
      padding: 1.458rem 0;
    }
  }

  .logo {
    &__img {
      width: 9.115rem;
      margin-right: 3.125rem;
    }
  }

  .btn.btn--viber {
    padding: 0.365rem 0.9375rem 0.365rem 0.417rem;
    letter-spacing: 0.036rem;

    &::before {
      width: 0.365rem;
      height: 0.365rem;
      top: 0.208rem;
      right: 0.15625rem;
    }

    .btn-viber {
      width: 1.667rem;
      height: 1.667rem;
      margin-right: 0.46875rem;

      img {
        width: 0.78125rem;
      }
    }
  }

  .nav {
    &__inner {
      padding-top: 1.042rem;
      letter-spacing: 0.28126rem;
    }
  }

  .circles {
    gap: 0.417rem;
    margin-right: 1.458rem;
    margin-left: 1.458rem;
    margin-top: 0.7rem;

    span {
      width: 0.521rem;
      height: 0.521rem;
    }
  }
  .hero-wrapper {
    margin-bottom: 6.771rem;

    .btn {
      margin-right: 2.448rem;
    }
  }

  .hero-time {

    &__img {
      width: 1.823rem;
      height: 1.823rem;
      margin-right: 1.51rem;
      img {
        width: 1.09375rem;
      }
    }

    p {
      line-height: 1.354rem;
    }
  }

  .hero-item {
    width: 50%;
    height: auto;
    padding: 1rem;

    br {
      display: none;
    }

    &__check {
      min-width: 1rem;
      margin-right: 0.5rem;
    }
  }

  .hero-scroll {
    display: none;
    width: 5.208rem;
    img {
      width: 2.083rem;
    }
  }

  .header-logo {
    .logo__img {
      margin-right: 0;
    }

    .logo__text {
      display: none;
    }
  }

  .header {
    .btn--pdf {
      display: none;
    }
  }

  .header-viber {
    display: none;
  }

  .header-phone {
    margin-right: 2rem;
  }

  .nav {
    z-index: 999;
    position: fixed;
    left: 0;
    top: 0;
    width: 75%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    transform: translateX(-100%);
    transition: all .4s ease-in;
  }

  .nav__close {
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 3rem;
    right: 3rem;
    display: block;
  }

  .nav__close::after, .nav__close::before {
    content: ' ';
    position: absolute;
    left: 50%;
    height: 100%;
    width: 3px;
    background-color: #a78b31;
    border-radius: 3px;
  }

  .nav__close::before {
    transform: rotate(
                    45deg);
  }

  .nav__close::after {
    transform: rotate(
                    -45deg);
  }

  .nav__inner {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav__item {
    padding: 1rem 2.292rem;
    font-size: 1.5rem;
    
    a {
      color: #000;
    }
  }

  .nav.active {
    transform: translateX(0);
  }

  .fz70 br {
    display: none;
  }

  .hero-wrapper {
    margin-bottom: 2rem;
    flex-direction: column;

    .btn {
      margin-bottom: 1rem;
      margin-right: 0;
    }
  }

  .btn--arrow img {
    width: 1rem;
    margin-left: 1.302rem;
  }

  .hero-items {
    flex-wrap: wrap;
  }


}