div.fancy-select {
	position: relative;
	font-size: 0.9375vw;
	color: #ffffff;
	cursor: pointer;
	width: 100%;
	z-index: 99;
}

div.fancy-select.disabled {
	opacity: 0.5;
}

div.fancy-select select:focus + div.trigger {

}

div.fancy-select select:focus + div.trigger.open {

}

div.fancy-select div.trigger {
	text-align: left;
	position: relative;
	display: flex;
	align-items: center;
	border-radius: 100px;
	padding-left: 1.823vw;
	background-color: #f4f4f4;
	border: none;
	color: #282728;
	font-size: 0.9375vw;
	height: 3.646vw;
	width: 100%;
	transition: all 240ms ease-out;
}

div.fancy-select div.trigger:after {
	content: '';
	border: solid #3974f4;
	border-width: 0 2px 2px 0;
	display: inline-block;
	padding: .15vw;
	position: absolute;
	top: 50%;
	transform: translateY(-50%) rotate(
			45deg);
	right: 1.5vw;
	transition: .3s;
	margin-top: -3px;
	z-index: 9;
}

div.fancy-select div.trigger.open {
	/*background-color: rgba(0, 0, 0, 0.25);
	color: #ffffff;*/
	box-shadow: none;
}

div.fancy-select div.trigger.open:after {
	/*border-top-color: #7A8498;*/
	-webkit-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
}

div.fancy-select ul.options {
	padding-left: 0;
	list-style: none;
	margin: 0;
	margin-top: -2px;
	position: absolute;
	/*top: 2rem;*/
	left: 0;
	visibility: hidden;
	opacity: 0;
	z-index: 50;
	overflow: auto;
	background-color: #f4f4f4;
	width: 100%;
	max-height: 30vw;
	overflow-y: auto;
	overflow-x: hidden;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;

	transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
	-webkit-transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
	-moz-transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
	-ms-transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
	-o-transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
}

div.fancy-select ul.options.open {
	visibility: visible;
	opacity: 1;
	border: 1px solid rgba(0, 0, 0, 0.07);
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	padding-top: 1.5vw;
	z-index: -1;
	top: 60%;
	/* have to use a non-visibility transition to prevent this iOS issue (bug?): */
	/*http://stackoverflow.com/questions/10736478/css-animation-visibility-visible-works-on-chrome-and-safari-but-not-on-ios*/
	transition: opacity 300ms ease-out, top 300ms ease-out;
	-webkit-transition: opacity 300ms ease-out, top 300ms ease-out;
	-moz-transition: opacity 300ms ease-out, top 300ms ease-out;
	-ms-transition: opacity 300ms ease-out, top 300ms ease-out;
	-o-transition: opacity 300ms ease-out, top 300ms ease-out;
}

div.fancy-select ul.options.overflowing {
	top: 100%;
	/*bottom: 40px;*/

	transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
	-webkit-transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
	-moz-transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
	-ms-transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
	-o-transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
}

div.fancy-select ul.options.overflowing.open {
	top: 100%;
	/*bottom: 50px;*/

	transition: opacity 300ms ease-out, bottom 300ms ease-out;
	-webkit-transition: opacity 300ms ease-out, bottom 300ms ease-out;
	-moz-transition: opacity 300ms ease-out, bottom 300ms ease-out;
	-ms-transition: opacity 300ms ease-out, bottom 300ms ease-out;
	-o-transition: opacity 300ms ease-out, bottom 300ms ease-out;
}

div.fancy-select ul.options li {
	text-align: left;
	padding: 1.1vw;
	color: #9699a9;
	cursor: pointer;
	white-space: nowrap;
	margin: 0;
	transition: all 150ms ease-out;
	-webkit-transition: all 150ms ease-out;
	-moz-transition: all 150ms ease-out;
	-ms-transition: all 150ms ease-out;
	-o-transition: all 150ms ease-out;
	/*white-space: nowrap;
	text-overflow: ellipsis;*/
}
div.fancy-select ul.options li:before{
	display: none;

}
div.fancy-select ul.options li.selected {
	/*background: #04a9f3;*/
	color: #e03d1c;
}

div.fancy-select ul.options li.hover {
	/*background: #04a9f3;*/
	color: #e03d1c;
	cursor: pointer;
}




.to_top {
position: fixed;
right: 2.5vw;
bottom: 1.5vw;
-webkit-box-shadow: 0 0 84px rgba(0, 108, 163, 0.41);
box-shadow: 0 0 84px rgba(0, 108, 163, 0.41);
background-color: #dcbf8b;
width: 3.5vw;
height: 3.5vw;
border-radius: 100%;
padding: 0.7vw;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-box-pack: center;
-ms-flex-pack: center;
justify-content: center;
cursor: pointer;
z-index: 189;
/*-webkit-animation: radial-pulse 1.5s infinite;*/
/*animation: radial-pulse 1.5s infinite;*/

opacity: 0;
-webkit-transform: scale(0);
-ms-transform: scale(0);
transform: scale(0);
-webkit-transition: all 0.4s;
-o-transition: all 0.4s;
transition: all 0.4s;
}
.to_top:before {
content: '';
position: relative;
border: 0.8vw solid transparent;
border-bottom: 1vw solid #fff;
-webkit-transform: translateY(-25%);
-ms-transform: translateY(-25%);
transform: translateY(-25%);
-webkit-transition: all 0.4s;
-o-transition: all 0.4s;
transition: all 0.4s;
}
.to_top.act {
opacity: 1;
-webkit-transform: scale(1);
-ms-transform: scale(1);
transform: scale(1);
}
.to_top:hover:before {
-webkit-transform: translateY(-37%);
-ms-transform: translateY(-37%);
transform: translateY(-37%);
}
/*@-webkit-keyframes radial-pulse {
0% {
-webkit-box-shadow: 0 0 0 0px rgba(243, 174, 25, 0.7);
box-shadow: 0 0 0 0px rgba(243, 174, 25, 0.7);
}
100% {
-webkit-box-shadow: 0 0 0 20px rgba(243, 174, 25, 0);
box-shadow: 0 0 0 20px rgba(243, 174, 25, 0);
}
}
@keyframes radial-pulse {
0% {
-webkit-box-shadow: 0 0 0 0px rgba(243, 174, 25, 0.7);
box-shadow: 0 0 0 0px rgba(243, 174, 25, 0.7);
}
100% {
-webkit-box-shadow: 0 0 0 20px rgba(243, 174, 25, 0);
box-shadow: 0 0 0 20px rgba(243, 174, 25, 0);
}*/
/*}*/
