.call {
  padding-top: 2.1875vw;
}

.call-form {
  background: url('../img/call-bg.jpg') center no-repeat;
  background-size: cover;
  position: relative;
  padding: 4.115vw 7.5vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 35px;
  color: #ffffff;

  &__right {
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn,
    .input {
      padding-top: 0;
      padding-bottom: 0;
      width: 17.34375vw;
      height: 3.646vw;
    }

    .input {
      margin-bottom: 0.833vw;
      background-color: #f7f6f6;
    }

    .btn {
      margin-bottom: 1.354vw;
    }

    .policy {
      margin-bottom: 2.34375vw;
    }
  }
}

.call-img {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 35.052vw;
}

.call-phone {
  display: flex;
  position: absolute;
  width: 8.90625vw;
  right: 0;
  bottom: 0;
}

.call-item {
  display: flex;
  align-items: center;
  margin-bottom: 1.927vw;

  &:last-child {
    margin-bottom: 0;
  }

  &__check {
    display: flex;
    width: 0.78125vw;
    margin-right: 1.25vw;
  }
}

@media (max-width: 900px) {
  .call {
    padding-top: 2.1875rem;
  }

  .call-form {
    padding: 2rem;
    padding-bottom: 35rem;
    flex-direction: column;
    align-items: center;

    br {
      display: none;
    }

    &__left {
      margin-bottom: 2rem;
    }

    &__right {

      .btn,
      .input {
        width: 100%;
        height: 4.5rem;
      }

      .input {
        margin-bottom: 0.833rem;
      }

      .btn {
        margin-bottom: 1.354rem;
      }

      .policy {
        margin-bottom: 2.34375rem;
      }
    }
  }

  .call-img {
    width: 35.052rem;
  }

  .call-phone {
    width: 8.90625rem;
  }

  .call-item {
    margin-bottom: 1.927rem;

    &__check {
      width: 0.78125rem;
      margin-right: 1.25rem;
    }
  }
}