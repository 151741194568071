.faq {
  padding-top: 0;
  padding-bottom: 8.28125vw;
}

.faq__items {
  display: flex;
  justify-content: center;
}

.faq__items-left {
  margin-right: 3.646vw;
}

.faq__item {
  padding-top: 2.24vw;
  padding-bottom: 2.24vw;
  border-bottom: 1px solid rgba(40,39,40,.15);
  cursor: pointer;
  width: 35.417vw;
}

.faq__item-top {
  display: flex;
  align-items: center;
  position: relative;
}

.faq__arrow {
  display: flex;
  width: 0.78125vw;
  margin-right: 1.5625vw;
}

.faq__arrow img:last-child {
  display: none;
}

.faq__icon {
  margin-left: auto;
  color: #ac8a10;
}

.faq__content {
  padding-left: 2.252vw;
  padding-right: 2.252vw;
  color: #6a6a6a;
  transition: all .3s ease;
  max-height: 0;
  overflow: hidden;
}

.faq__item.active .faq__arrow img:first-child {
  display: none;
}

.faq__item.active .faq__arrow img:last-child {
  display: flex;
}

.faq__item.active .faq__content {
  padding-top: 1.40625vw;
  max-height: 1000px;
}

@media (max-width: 900px) {
  .faq {
    padding-bottom: 8.28125rem;
  }

  .faq__items-left {
    margin-right: 0;
  }

  .faq__item {
    padding-top: 2.24rem;
    padding-bottom: 2.24rem;
    width: 100%;
  }

  .faq__arrow {
    width: 0.78125rem;
    margin-right: 1.5625rem;
  }

  .faq__content {
    padding-left: 2.252rem;
    padding-right: 2.252rem;
  }

  .faq__item.active .faq__content {
    padding-top: 1.40625rem;
  }

  .faq__items {
    flex-direction: column;
  }
}