.design {
  padding-top: 1.042vw;
  padding-bottom: 0;
}

.design-form {
  padding: 4.792vw 6.042vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 50px;
  background: url('../img/design-bg.png') center no-repeat;
  background-size: cover;
  color: #ffffff;
  position: relative;

  &__img {
    display: flex;
    width: 34.115vw;
    position: absolute;
    bottom: 0;
    right: 20.208vw;
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .btn,
    .input {
      width: 15.99vw;
      height: 3.646vw;
      padding-top: 0;
      padding-bottom: 0;
    }
    
    .input {
      margin-bottom: 0.833vw;
      background-color: #fff;
    }
  }
}

@media (max-width: 900px) {
  .design {
    padding-top: 1.042rem;
  }

  .design-form {
    padding: 2rem;
    padding-bottom: 20rem;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .fz27 br {
      display: none;
    }

    &__left {
      margin-bottom: 2rem;
    }

    &__img {
      width: 23.115rem;
      right: 3rem;
    }

    &__right {

      .btn,
      .input {
        width: 100%;
        height: 4.5rem;
      }

      .input {
        margin-bottom: 0.833rem;
      }
    }
  }

  .fz48 br {
    display: none;
  }
}