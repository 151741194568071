.contacts {
  padding: 3.385vw 0;
  background-color: #19191b;
  color: #ffffff;
  border-bottom: 2px dotted #c3cbd9;
}

.contacts-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contacts-item {
  display: flex;
  align-items: center;

  &__img {
    display: flex;
    width: 1.25vw;
    margin-right: 1.09375vw;
  }
}

.contacts-socials {
  display: flex;
}

.contacts-social {
  display: flex;
  width: 2.292vw;
  margin: 0 0.365vw;
  transition: 0.3s;

  &:hover {
    transform: scale(1.05);
  }
}

.footer {
  padding: 2.083vw 0;
  background-color: #19191b;
  color: #ffffff;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media (max-width: 900px) {
  .contacts {
    padding: 3.385rem 0;
  }

  .contacts-item {
    &__img {
      width: 1.25rem;
      margin-right: 1.09375rem;
    }
  }

  .contacts-social {
    width: 2.292rem;
    margin: 0 0.365rem;
  }

  .footer {
    padding: 2.083rem 0;
  }

  .contacts-inner {
    flex-direction: column;
    align-items: center;

    .header-phone {
      margin-right: 0;
      margin-bottom: 2rem;
    }

    .contacts-item {
      margin-bottom: 2rem;
    }
  }

  .footer__inner {
    flex-direction: column;
    align-items: center;

    .logo__text {
      display: none;
    }

    .logo__img {
      margin-right: 0;
      margin-bottom: 2rem;
    }
  }

  .footer__text {
    margin-bottom: 2rem;
  }

  .footer__item {
    margin-top: 2rem;
  }
}