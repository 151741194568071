.widget-menu {
  position: fixed;
  right: 0;
  top: 15vw;
  z-index: 998;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
}

.widget-menu.active {
  pointer-events: all;
}

.widget-link {
  width: 4.896vw;
  height: 5.052vw;
  border-radius: 15px 0 0 15px;
  background-color: #a78b31;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 0.208vw;
  transform: translateX(158%);
  transition: .4s;
}

.widget-menu.active .widget-link {
  transform: translateX(0);
}

.btn--burger-wrapper {
  position: relative;
  margin-top: -1.1vw;
  transition-duration: .5s;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .4s;
  width: 1.5625vw;
  height: 1.5625vw;
  background-color: #fff;
  pointer-events: all;
}

.btn--burger {
  width: 0.625vw;
  height: 0.104vw;
  background-color: transparent;
}

.btn--burger:after, .btn--burger:before {
  content: "";
  position: absolute;
  display: block;
  width: 0.625vw;
  height: 0.104vw;
  background-color: #282728;
  transition: .4s;
}

.btn--burger:before {
  top: 0.625vw;
  transform: rotate(
                  -25deg);
}

.btn--burger-wrapper.active .btn--burger:before {
  transform: rotate(
                  45deg);
  top: 0.78125vw;
}

.btn--burger:after {
  bottom: 0.573vw;
  transform: rotate(
                  25deg);
}

.btn--burger-wrapper.active .btn--burger:after {
  transform: rotate(
                  -45deg);
  bottom: 0.677vw;
}

.modal-overlay {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: rgba(0,0,0,.7);
}

.modal {
  position: absolute;
  left: 50%;
  transition: all .8s ease;
  transform-style: preserve-3d;
  z-index: 77;
  top: 5vw;
  width: auto;
  transform: translateX(-50%);
  text-align: center;
  margin-bottom: 0;
  border: 0;
  border-radius: 30px;
  background-repeat: no-repeat;
  background-size: cover;
}

.modal__close {
  display: flex;
  position: absolute;
  top: 1.667vw;
  right: 1.5625vw;
  width: 0.885vw;
  cursor: pointer;
  transition: .3s;
}

.modal-call {
  padding: 2.552vw 5.208vw 2.552vw 20.3125vw;
  background-image: url(../img/modal-bg.jpg);
  background-size: cover;
  color: #ffffff;

  .modal__form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .input,
  .btn {
    padding-top: 0;
    padding-bottom: 0;
    width: 13.49vw;
    height: 2.865vw;
    margin-bottom: 0.573vw;
  }

  .input {
    background-color: #f7f6f6;
    padding-left: 1.979vw;
  }

  .policy {
    margin-top: 0.417vw;
  }
}

.modal-person {
  position: absolute;
  left: 1.302vw;
  bottom: 0;

  &__img {
    display: flex;
    width: 15.78125vw;
  }

  &__name {
    position: absolute;
    top: 2.8vw;
    left: 12vw;
    text-align: left;

    .fz13 {
      opacity: 0.43;
    }
  }
}

@media (max-width: 900px) {
  .widget-menu {
    top: 15rem;
  }

  .widget-link {
    width: 7.596rem;
    height: 6.652rem;
    margin-bottom: 0.208rem;
  }

  .btn--burger-wrapper {
    margin-top: -1.1rem;
    width: 1.5625rem;
    height: 1.5625rem;
  }

  .btn--burger {
    width: 0.625rem;
    height: 0.104rem;
  }

  .btn--burger:after, .btn--burger:before {
    width: 0.625rem;
    height: 0.104rem;
  }

  .btn--burger:before {
    top: 0.625rem;
  }

  .btn--burger-wrapper.active .btn--burger:before {
    top: 0.78125rem;
  }

  .btn--burger:after {
    bottom: 0.573rem;
  }

  .btn--burger-wrapper.active .btn--burger:after {
    bottom: 0.677rem;
  }

  .modal {
    top: 5rem;
    width: 30rem;
  }

  .modal__close {
    top: 1.667rem;
    right: 1.5625rem;
    width: 0.885rem;
  }

  .modal-call {
    padding: 2rem;
    padding-bottom: 20rem;

    .input,
    .btn {
      width: 100%;
      height: 4.5rem;
      margin-bottom: 0.573rem;
    }

    .input {
      padding-left: 1.979rem;
    }

    .policy {
      margin-top: 0.417rem;
    }
  }

  .modal-person {
    left: 5.302rem;

    &__img {
      width: 15.78125rem;
    }

    &__name {
      top: 2.8rem;
      left: 12rem;
    }
  }
}