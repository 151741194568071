.warranty {
  background: url('../img/warranty-bg.png') center no-repeat;
  background-size: cover;
  padding-bottom: 0;
  z-index: 1;
}

.modal-politics {
  background-color: #272727;
  padding: 2rem;
}

.modal__close {
  z-index: 1;
}

.warranty-inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: #ffffff;
}

.warranty-img {
  display: flex;

  &--left {
    width: 19.84375vw;
  }

  &--right {
    width: 22.083vw;
  }
}

.warranty-mid {
  width: 39.0625vw;
}

.warranty-items {
  display: flex;
  flex-wrap: wrap;
}

.warranty-item {
  display: flex;
  align-items: center;
  width: 18.229vw;
  margin-bottom: 1.979vw;

  &__check {
    display: flex;
    width: 0.78125vw;
    margin-right: 25px;
  }
}

.warranty-wrapper {
  display: flex;
  justify-content: space-between;
}

.warranty-nums {
  display: flex;
}

.warranty-num {
  &:first-child {
    margin-right: 4.167vw;
  }

  &__check {
    width: 0.833vw;
    height: 0.833vw;
    background-color: #ac8a10;
    border-radius: 50%;
    margin-bottom: 1vw;
  }
}

.warranty-video {
  position: relative;

  &__img {
    display: flex;
    width: 17.1875vw;
  }
}

.play {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 3.073vw;
  height: 3.073vw;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 49px rgba(40, 39, 40, 0.25);
  background-color: #ac8a10;
  border-radius: 50%;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .2;
    border-radius: 50%;
    animation: animPulse 2s linear infinite;
    z-index: -1;
    background-color: #ac8a10;
  }

  img {
    width: 1.771vw;
  }
}

@media (max-width: 900px) {
  .warranty-img {
    &--left {
      width: 19.84375rem;
      display: none;
    }

    &--right {
      width: 22.083rem;
      display: none;
    }
  }

  .warranty-mid {
    width: 100%;

    br {
      display: none;
    }
  }

  .warranty {
    .fz70 {
      margin-bottom: 7rem;
    }
  }

  .warranty-item {
    width: 100%;
    margin-bottom: 1.979rem;

    &__check {
      width: 1.5rem;
    }
  }

  .warranty-num {
    &:first-child {
      margin-right: 4.167rem;
    }

    &__check {
      width: 0.833rem;
      height: 0.833rem;
      margin-bottom: 1rem;
    }
  }

  .warranty-video {
    &__img {
      width: 17.1875rem;
    }
  }

  .play {
    width: 3.073rem;
    height: 3.073rem;

    img {
      width: 1.771rem;
    }
  }

  .warranty-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .warranty-nums {
    margin-bottom: 2rem;
  }
}