.material {
  padding-top: 6.25vw;
  background: url('../img/material-bg.png') bottom no-repeat;
  background-size: cover;

  .container {
    position: relative;
    z-index: 1;
  }
}

.material-inner {
  padding-left: 3.90625vw;
}

.material-steps {
  display: flex;
  margin-bottom: 5.208vw;
}

.material-step {
  margin-right: 3.90625vw;

  &:last-child {
    margin-right: 0;
  }

  &__img {
    display: flex;
    width: 5.208vw;
    height: 5.208vw;
    background-color: #ffffff;
    border-radius: 50%;
    position: relative;
    margin-bottom: 1.146vw;

    img {
      position: absolute;
      max-width: unset;
      max-height: unset;
    }
  }

  &--estimate {
    .material-step__img img {
      width: 7.083vw;
      top: -0.78125vw;
      left: -1.458vw;
    }
  }

  &--contract {
    .material-step__img img {
      width: 7.65625vw;
      top: 0;
      left: 0;
    }
  }

  &--time {
    .material-step__img img {
      width: 5.833vw;
      top: unset;
      bottom: 0;
      left: 0;
    }
  }

  &--pay {
    .material-step__img img {
      width: 5.677vw;
      top: unset;
      bottom: 0;
      left: 0;
    }
  }
}

.material-nums {
  display: flex;
  margin-bottom: 6.71875vw;

  &__num {
    background-clip: text;
    background-image: linear-gradient(to top, #282728 0%, #eaeaea 100%);
    color: transparent;
  }
}

.material-num {
  margin-right: 5.208vw;

  &:last-child {
    margin-right: 0;
  }
}

.material-words {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  text-align: center;
  padding-right: 2.7vw;

  &::before {
    content: 'БЕСПЛАТНО';
    letter-spacing: 3.125vw;
    font-size: 10.35vw;
    opacity: 0.17;
    background-image: radial-gradient(circle 1888px at 30.94% -115%, #bfbebe 0%, #282728 100%);
    background-clip: text;
    color: transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.material-img {
  display: flex;
  width: 72.135vw;
  position: absolute;
  right: 0;
  top: -4.3vw;
}

@media (max-width: 900px) {
  .material {
    padding-top: 6.25rem;
  }

  .material-inner {
    padding-left: 0;
  }

  .material-steps {
    margin-bottom: 2rem;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .material-step {
    margin-right: 0;
    width: 49%;
    margin-bottom: 2rem;

    br {
      display: none;
    }

    &__img {
      width: 5.208rem;
      height: 5.208rem;
      margin-bottom: 1.146rem;
    }

    &--estimate {
      .material-step__img img {
        width: 7.083rem;
        top: -0.78125rem;
        left: -1.458rem;
      }
    }

    &--contract {
      .material-step__img img {
        width: 7.65625rem;
      }
    }

    &--time {
      .material-step__img img {
        width: 5.833rem;
      }
    }

    &--pay {
      .material-step__img img {
        width: 5.677rem;
      }
    }
  }

  .material-nums {
    margin-bottom: 2rem;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .material-num {
    margin-right: 0;
    width: 49%;
    margin-bottom: 2rem;

    br {
      display: none;
    }
  }

  .material-words {
    padding-right: 0;
    flex-direction: column;

    p {
      margin-bottom: 1rem;
    }

    &::before {
      opacity: .25;
    }
  }

  .material-img {
    width: 72.135rem;
    top: -4.3rem;
    display: none;
  }

  .fz64 {
    font-size: 3.333rem;
  }
}