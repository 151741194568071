.download {
  padding-top: 6.927vw;
}

.download-inner {
  padding-left: 3.90625vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .input,
    .btn {
      width: 17.34375vw;
      height: 4.167vw;
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 0.833vw;
    }

    .input {
      background-color: #f7f6f6;
    }
  }

  .policy {
    margin-top: 0.833vw;
    
    &__text a {
      color: #000;
    }
  }
}

.download-img {
  display: flex;
  width: 73.4375vw;
  position: absolute;
  right: -6.875vw;
  top: -8.7vw;
  z-index: -1;
}

.download-date {
  color: #c4a227;
  position: absolute;
  left: 24.8vw;
  top: 17.1vw;
}

@media (max-width: 900px) {
  .download-date {
    display: none;
  }

  .download {
    padding-top: 26rem;
  }

  .download-inner {
    padding-left: 0;
    text-align: center;

    &__wrapper {
      .input,
      .btn {
        width: 100%;
        height: 4.5rem;
        margin-bottom: 0.833rem;
      }
    }

    .policy {
      margin-top: 0.833rem;
    }
  }

  .download-img {
    width: 29.4375rem;
    right: 2.125rem;
    top: -2.7rem;
  }
}