@font-face {
  font-family: "Jost";
  src: url("../fonts/Jost-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Jost";
  src: url("../fonts/Jost-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Jost";
  src: url("../fonts/Jost-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Jost";
  src: url("../fonts/Jost-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Jost";
  src: url("../fonts/Jost-SemiBold.ttf") format("truetype");
  font-weight: 600;
}