.online {
  position: relative;
  padding-left: 0.625vw;

  &::before {
    content: '';
    border-radius: 50%;
    width: 0.365vw;
    height: 0.365vw;
    background-color: #52b060;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    animation: online 2s infinite;
  }
}

.socials {
  display: flex;
  gap: 0.625vw;
}

.socials-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.604vw;
  height: 2.604vw;
  border-radius: 50%;
  transition: 0.3s;

  &:hover {
    transform: scale(1.05);
  }

  &.is-viber {
    background-color: #7460f3;

    img {
      width: 1.042vw;
    }
  }

  &.is-whatsapp {
    background-color: #25d266;

    img {
      width: 1.042vw;
    }
  }

  &.is-telegram {
    background-color: #00a2f2;

    img {
      width: 0.99vw;
    }
  }
}

.phone {
  display: flex;
  align-items: center;

  img {
    width: 0.885vw;
    margin-right: 0.521vw;
    margin-bottom: 0.2vw;
  }
}

.colored {
  color: #ad8a06;
}

.mbl {
  margin-bottom: 2.708vw;
}

.btn.btn--pdf {
  .btn-logo {
    margin-right: 0.633vw;
    transition: 0.3s;
  }

  &:hover {
    .btn-logo {
      color: #ffffff;
    }
  }
}

.input, .area {
  padding: 1.5625vw 2.604vw;
  border-radius: 100px;
  background-color: #252525;
  color: #7c7c7c;
  width: 100%;
  position: relative;
  border: 0;

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #7c7c7c;
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #7c7c7c;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: #7c7c7c;
  }
}

.fz10 {
  font-size: 0.521vw; }

.fz11 {
  font-size: 0.573vw; }

.fz13 {
  font-size: 0.677vw; }

.fz14 {
  font-size: 0.729vw; }

.fz15 {
  font-size: 0.78125vw; }

.fz16 {
  font-size: 0.833vw; }

.fz17 {
  font-size: 0.885vw; }

.fz18 {
  font-size: 0.9375vw; }

.fz20 {
  font-size: 1.042vw; }

.fz22 {
  font-size: 1.146vw; }

.fz24 {
  font-size: 1.25vw; }

.fz25 {
  font-size: 1.302vw; }

.fz27 {
  font-size: 1.40625vw; }

.fz30 {
  font-size: 1.5625vw; }

.fz36 {
  font-size: 1.875vw; }

.fz40 {
  font-size: 2.083vw; }

.fz46 {
  font-size: 2.396vw; }

.fz48 {
  font-size: 2.5vw; }
.fz50 {
  font-size: 2.604vw;
}
.fz55 {
  font-size: 2.865vw; }

.fz60 {
  font-size: 3.125vw; }
.fz64 {
  font-size: 3.333vw; }
.fz70 {
  font-size: 3.646vw;
}

.fz75 {
  font-size: 3.90625vw; }
.fz100 {
  font-size: 5.208vw;
}

.fz115 {
  font-size: 5.99vw;
}

@keyframes online {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }

  50% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animPulse {
  0% {
    opacity: 0;
    transform: scale(1);
  }

  50% {
    opacity: .5;
  }

  100% {
    opacity: 0;
    transform: scale(1.8);
  }
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-25%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes loadingBg {
  0%{
    background-position: 2%;
  }

  100%{
    background-position: 100%;
  }
}

@media (max-width: 900px) {
  .container-big {
    max-width: 90vw;
    margin: 0 auto;
  }

  .fz10 {
    font-size: 1rem;
  }

  .fz11 {
    font-size: 1rem;
  }

  .fz13 {
    font-size: 1rem;
  }

  .fz14 {
    font-size: 1.1rem;
  }

  .fz15 {
    font-size: 1.2rem;
  }

  .fz16 {
    font-size: 1.3rem;
  }

  .fz17 {
    font-size: 1.3rem;
  }

  .fz18 {
    font-size: 1.5rem;
  }

  .fz20 {
    font-size: 1.8rem;
  }

  .fz22 {
    font-size: 1.8rem;
  }

  .fz24 {
    font-size: 1.8rem;
  }

  .fz25 {
    font-size: 2rem;
  }

  .fz27 {
    font-size: 1.40625rem;
  }

  .fz30 {
    font-size: 1.8rem;
  }

  .fz36 {
    font-size: 1.875rem;
  }

  .fz40 {
    font-size: 2.083rem;
  }

  .fz46 {
    font-size: 2.396rem;
  }

  .fz48 {
    font-size: 2.5rem;
  }
  .fz55 {
    font-size: 2.565rem;
  }

  .fz60 {
    font-size: 3.125rem;
  }
  .fz70 {
    font-size: 3.3rem;
  }

  .fz75 {
    font-size: 3.5rem;
  }
  .fz100 {
    font-size: 3.5rem;
  }

  .online {
    padding-left: 1.198rem;

    &::before {
      width: .417rem;
      height: .417rem;
      top: 0.3rem;
    }
  }

  .socials {
    gap: 0.625rem;
  }

  .socials-item {
    width: 3rem;
    height: 3rem;

    &.is-whatsapp {
      img {
        width: 1.2rem;
      }
    }

    &.is-telegram {
      img {
        width: 1.2rem;
      }
    }
  }

  .phone {
    img {
      width: 0.677rem;
      margin-right: 0.521rem;
      margin-bottom: 0.3rem;
    }
  }

  .mbl {
    margin-bottom: 2.708rem;
  }

  .input, .area {
    padding: 1.5625rem 2.604rem;
    line-height: 1.042rem;
  }

  .btn {
    padding: 2.135rem 2.396rem;
  }

  .btn--arrows {
    gap: 1rem;
  }

  .btn.btn--pdf {
    .btn-logo {
      margin-right: 0.833rem;
    }
  }
}
